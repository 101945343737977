<template>
  <div>
    <PageDynamicBreadcrumb />

    <div class="row items-center justify-start q-mb-lg">
      <DateRangeSelector
        :initial-start-date="startDate"
        :initial-end-date="endDate"
        @update="
          dates => {
            updateDateRange(dates);
            getLeads();
          }
        "
        url-state
      />
    </div>

    <DataTable
      table-key="leadDelivery"
      row-key="id"
      title="Lead Delivery"
      :columns="columns"
      :rows="leads"
      :loading="loading"
      :pagination="{
        sortBy: 'id',
        descending: true,
        page: 1,
        rowsPerPage: 25
      }"
      :downloadable="true"
      :refreshable="true"
      :customizable="false"
      :filterable="true"
      :searchable="true"
      @update-rows="getLeads()"
    >
      <template v-slot:top-additional>
        <div class="full-width q-py-sm row q-gutter-x-md q-header--bordered">
          <div
            v-for="(totalValue, totalLabel) in totals"
            :key="totalLabel + '_leadDeliveryStatusCodeTotal'"
            class="q-mr-lg"
          >
            <small class="q-mb-sm text-grey text-uppercase">
              {{ totalLabel }}
            </small>
            <h5 class="q-my-none text-weight-bold text-primary">
              {{ loading ? "-" : Intl.NumberFormat().format(totalValue) }}
            </h5>
          </div>
        </div>
      </template>

      <template v-slot:status_code="row">
        <q-icon
          :name="leadStatusCodeMap[row.status_code].icon"
          size="sm"
          :color="leadStatusCodeMap[row.status_code].color"
          class="flex q-mr-sm"
        >
          <q-tooltip
            anchor="center right"
            self="center left"
            :offset="[10, 10]"
          >
            {{ leadStatusCodeMap[row.status_code].message }}
          </q-tooltip>
        </q-icon>
      </template>
    </DataTable>
  </div>
</template>

<script>
import PageDynamicBreadcrumb from "@/components/UI/PageDynamicBreadcrumb";
import axios from "axios";
import moment from "moment/moment";
import DataTable from "@/components/DataTable";
import DateRangeSelector from "@/components/UI/DateRangeSelector";

export default {
  name: "LeadDeliveryReport",
  components: {
    DateRangeSelector,
    DataTable,
    PageDynamicBreadcrumb
  },
  data() {
    let leadStatusCodeMap = [
      {
        message: "Pending",
        icon: "pending",
        color: "subtle"
      },
      {
        message: "Successful",
        icon: "check_circle",
        color: "positive"
      },
      {
        message: "Rejected",
        icon: "cancel",
        color: "negative"
      },
      {
        message: "Holding",
        icon: "error",
        color: "warning"
      }
    ];
    return {
      loading: true,
      startDate: this.$route.query.start_date
        ? this.$route.query.start_date
        : moment().format("YYYY-MM-DD"),
      endDate: this.$route.query.end_date
        ? this.$route.query.end_date
        : moment().format("YYYY-MM-DD"),
      datePreset: this.$route.query.date_preset
        ? this.$route.query.date_preset
        : "",
      pagination: {
        sortBy: "id",
        descending: true,
        rowsPerPage: 25
      },
      totals: {
        total: 0,
        pending: 0,
        successful: 0,
        rejected: 0
      },
      leads: [],
      pendingGetLeadsRequests: [],
      leadStatusCodeMap: leadStatusCodeMap,
      columns: [
        {
          name: "status_code",
          label: "Status",
          field: "status_code",
          hideField: true,
          format: val => leadStatusCodeMap[val].message,
          sortable: true,
          sort: (a, b) => this.sortInteger(b, a),
          type: "dimension"
        },
        {
          name: "id",
          label: "Lead ID",
          field: "id",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "dimension",
          filterable: false
        },
        {
          name: "business_account_name",
          label: "Business Account",
          field: "business_account_name",
          sortable: true,
          type: "dimension"
        },
        {
          name: "offer_id",
          label: "Offer ID",
          field: "offer_id",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "dimension"
        },
        {
          name: "full_name",
          label: "Full Name",
          field: "full_name",
          sortable: true,
          type: "dimension",
          filterable: false
        },
        {
          name: "phone",
          label: "Phone",
          field: "phone",
          sortable: true,
          type: "dimension",
          filterable: false
        },
        {
          name: "email",
          label: "Email",
          field: "email",
          sortable: true,
          type: "dimension",
          filterable: false
        },
        {
          name: "postal_code",
          label: "Postal Code",
          field: "postal_code",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "dimension",
          filterable: false
        },
        {
          name: "vin",
          label: "VIN",
          field: "vin",
          sortable: true,
          type: "dimension",
          filterable: false
        },
        {
          name: "dealer_name",
          label: "Dealership Name",
          field: "dealer_name",
          sortable: true,
          filterable: false,
          type: "dimension"
        },
        {
          name: "distance_to_dealer",
          label: "Distance to Dealership",
          field: row =>
            row.distance_to_dealer === "-" ? 0 : row.distance_to_dealer,
          format: val =>
            val === "-"
              ? "-"
              : Intl.NumberFormat().format(val.toFixed(2)) + " miles",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "metric",
          metricType: "number",
          align: "left"
        },
        {
          name: "time_added_cst",
          label: "Time Created",
          field: "time_added_cst",
          format: val => (!val ? "-" : moment(val).format("lll")),
          sortable: true,
          sort: (a, b) => this.sortDate(a, b),
          type: "metric",
          metricType: "date",
          align: "left"
        },
        {
          name: "time_attempted_cst",
          label: "Time Delivered",
          field: "time_attempted_cst",
          format: val => (!val ? "-" : moment(val).format("lll")),
          sortable: true,
          sort: (a, b) => this.sortDate(a, b),
          type: "metric",
          metricType: "date",
          align: "left"
        },
        {
          name: "error_message",
          label: "Internal Error Message",
          field: row =>
            row.error_message && row.error_message.length > 0
              ? row.error_message
              : "-",
          sortable: false,
          type: "dimension",
          filterable: false
        },
        {
          name: "business_response_data_cleaned",
          label: "Business Response",
          field: row =>
            row.business_response_data_cleaned &&
            row.business_response_data_cleaned.length > 0
              ? row.business_response_data_cleaned
              : "-",
          sortable: false,
          type: "dimension",
          filterable: false
        }
      ]
    };
  },
  beforeMount() {
    if (this.datePreset.length > 0) {
      let dateRange = this.getDatePresetRange(this.datePreset);
      this.startDate = dateRange.from;
      this.endDate = dateRange.to;
    }
  },
  mounted() {
    this.getLeads();
  },
  methods: {
    updateDateRange(dates = null) {
      if (dates) {
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
      }
    },
    getLeads() {
      this.loading = true;

      // Abort any existing requests
      if (this.pendingGetLeadsRequests.length > 0) {
        this.pendingGetLeadsRequests.forEach(controller => {
          controller.abort();
        });
        this.pendingGetLeadsRequests = [];
      }
      let controller = new AbortController();
      let { signal } = controller;
      this.pendingGetLeadsRequests.push(controller);

      this.leads = [];

      axios({
        url: "https://api.jumpfeed.com/products/vehicles/leads",
        params: {
          start_date: this.startDate,
          end_date: this.endDate
        },
        method: "GET",
        signal: signal
      })
        .then(response => {
          this.leads = response.data.data.rows;
          this.totals = response.data.data.metadata.totals;

          this.loading = false;
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            return;
          }
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
